
.modal .layout {
  display: grid;
  grid-template-columns: 100px auto 100px;
  grid-template-rows: 35px auto 28px;
  grid-template-areas:
    "void title exit"
    "left-side main right-side"
    "left-side info right-side";
  position: fixed;
  left: 0; right: 0; top: 0; bottom: 0;
  background-color: rgb(64, 64, 64, .7);
  z-index: 10000;
}

.modal .layout .side {
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.modal .layout .side > span {
  font-size: 7rem;
  font-weight: bold;
  margin: auto;
}

.modal .layout .side.disabled {
  cursor: auto;
  opacity: .2;
}

.modal .layout .left {
  grid-area: left-side;
}

.modal .layout .right {
  grid-area: right-side;
}

.modal .layout .project {
  grid-area: main;
  margin: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.modal .layout .title {
  grid-area: title;
  text-align: center;
}

.modal .layout .info {
  grid-area: info;
  text-align: center;
}

.modal .layout .void {
  grid-area: void;
}

.modal .layout .exit {
  grid-area: exit;
  text-align: right;
}

.modal .layout .exit :global(.fa) {
  font-size: 3rem;
  padding: 4px 8px;
  cursor: pointer;
}


/* - - - - - Styles for smart-phones and tablets - - - - - */
@media (max-width: 767px) {
  .modal .layout {
    grid-template-columns: 50px auto 50px;
  }

  .modal .layout .side > span {
    font-size: 4rem;
  }
}
