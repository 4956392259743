
.cellDetails table td {
  padding: 2px 8px;
}

.cellDetails table td label {
  font-weight: bold;
}

.cellPhoto .photo {
  margin: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}



/* - - - - - Styles for smart-phones and tablets - - - - - */
@media (max-width: 767px) {
  .cellPhoto div.photo {
    display: none;
  }
}




/* - - - - - Styles for larger screens (computers) - - - - - */
@media (min-width: 768px) {
  .grid {
    display: grid;
    height: calc(100vh - 100px);
    grid-template-columns: minmax(350px, 20%) auto;
  }

  .cellPhoto {
    text-align: center;
  }

  .cellPhoto div.photo {
    height: 100%;
  }

  .cellPhoto img {
    display: none !important;
  }

  .cellDetails {
    display: flex;
    height: 100%;
    align-items: center;
  }
}