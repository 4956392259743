
ul.list {
  padding: 0;
  list-style-type: none;
}

ul.list > li {
  text-align: center;
}

ul.list > li img {
  max-height: 250px;
  transition: opacity .4s ease;
  -webkit-transform: translateZ(0); /* Chrome bug */
}

ul.list > li > a > span {
  display: block;
  padding: 3px;
}

/* Styles for smart-phones and tablets */
@media (max-width: 767px) {
  img.responsive { margin: 0 auto; }
  .page-projects ul > li:not(:first-child) { margin-top: 15px; }
}

/* Styles for larger screens (computers) */
@media (min-width: 768px) {
  ul.list > li { float: left; }
  ul.list > li a { margin: 10px; }
  ul.list > li img { height: 250px; opacity: 0.8; }
  ul.list > li:hover img { opacity: 1; }
}

.startSlideShowButtonRow {
  margin-bottom:  .5em;
}
