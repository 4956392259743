html {
  min-height: 100%;
  position: relative;
  margin: 0;
}
body {
  margin: 0;
  _font-family: Tahoma;
}
:global(.no-wrap) {
  white-space: nowrap;
}
a, h1, h2, h3, .color, .navbar .navbar-brand, .navbar-nav>li a { color: #000; }
.navbar-brand { padding-right: 0; }
h1 { font-size: 32px; border-bottom: 1px solid #43853D; padding-bottom: 3px; margin-left: 10px; margin-right: 10px; }
h2 { font-size: 26px; padding-left: 5px; }
h3 { font-size: 21px; padding-left: 5px; }
h1 span, h2 span, h3 span { font-style: italic; font-size: 75%; }
a, a:visited { text-decoration: none; }
a:hover { color: #555; transition: color 0.5s; }
ul { padding: 0; list-style-type: none; }
.navbar-nav > li > a { font-variant: small-caps; }


.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button > :global(.fa):first-child {
  padding-right: .5rem;
}

button > :global(.fa):laft-child {
  padding-left: .5rem;
}

img:global(.responsive) {
  display: inline-block;
  max-width: 100%;
  height: auto;
}


/* - - - - - Styles for smart-phones and tablets - - - - - */
@media (max-width: 767px) {
  /*.navbar { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; background-color: white; border-width: 0 0 1px; border-radius: 0; }
  .navbar-toggle .icon-bar { height: 4px; background-color: #000; transition: background-color 1s ease; }
  .navbar-toggle:hover .icon-bar { background-color: #444; }

  .navbar-text { font-size: 36px; text-transform: uppercase; margin-top: 4px; display: inline-block; }
  .navbar-nav { font-size: 120%; }*/

  img:global(.responsive) {
    margin: 0 auto;
  }
}

/* - - - - - Styles for larger screens (computers) - - - - - */
@media (min-width: 768px) {
  body {
    height: 100vh;
  }

  /*.navbar-header, .navbar-brand, .navbar-text { float: none; text-align: center; }
  .navbar-text { display: inline-block; padding: 3px 20px; font-size: 130%; letter-spacing: 1px; font-weight: bold; text-transform: uppercase; }
  .navbar-collapse.collapse { text-align: center; }
  .navbar-nav { float: none; margin: 0 auto; display: inline-block; border-top: 1px solid #000; border-bottom: 1px solid #000; }
  .navbar-nav > li { float: none; display: inline-block; margin-left: -2px; margin-right: -2px; }
  .navbar-nav > li > a { display: inline-block; text-align: center; padding: 10px 25px; transition: background-color .6s ease; }*/

  h1, h2, h3 {
    text-align: center;
  }
}
